/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 100 },

  {
    label: '年月',
    kname: 'title' },

  {
    label: '生成时间',
    kname: 'source' },

  {
    label: '状态',
    kname: 'createTime' },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var detailOption = function detailOption(ctx) {return [
  { label: '审核目的', kname: 'aa' },
  { label: '审核范围', kname: 'aa' },
  { label: '审核依据', kname: 'aa' },
  { label: '参加人员', kname: 'aa' },
  { label: '审核日期', kname: 'aa', span: 50 },
  { label: '编制', kname: 'aa', span: 50 },
  { label: '管理评审综述', kname: 'aa' },
  { label: '管评输出', kname: 'aa' },
  { label: '附 各部门工作报告', kname: 'aa' },
  { label: '质量负责人', kname: 'aa' },
  { label: '主任', kname: 'aa' }];};


export var searchOption = [
{
  kname: 'category',
  label: '状态：',
  component: {
    name: 'selector',
    props: {
      list: formStatus,
      clearable: true } } },



{
  kname: 'tt',
  label: '月份：',
  component: {
    name: 'c-date-picker',
    props: {
      clearable: true,
      type: 'monthrange' } } }];